import React , { useEffect , useState } from 'react';
import Question from '../../components/dashboard/question/Question';
import Layout from '../../components/dashboard/layout/Layout';
import Clue from '../../components/dashboard/clue/Clue';
import "./Dashboard.css";
import "../../components/dashboard/clue/Clue.css";
import { useParams } from "react-router-dom";
import TranslateNameFromPathForRouting from '../../helpers/TranslateNameFromPathForRouting';
import { TranslateNameFromPathToSectionForBasicQuestions, TranslateNameFromPathToSectionForSpecialistQuestions } from '../../helpers/TranslateNameFromPathToSection';
import { useSelector , useDispatch } from 'react-redux';
import { setActiveQuestion, setCurrentSection, resetActiveQuestion, deleteAllQuestionFromExList } from '../../redux/actions/departamentActions';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import { showToast , resetModalSettings } from '../../redux/actions/notificationActions';
import { useHistory } from "react-router-dom";
import Separator from '../../components/dashboard/separator/Separator';
import ValidateUrl from '../../helpers/ValidateUrl';

const DepartmentQuestion = () => {

    const stores = useSelector((state) => state);

    const user = stores.user.user;
    const questionsStore = stores.questions;
    const departamentStore = stores.departament;
    const activeQuestion = departamentStore.activeQuestion;
    const questionsProgress = new Map(Object.entries(questionsStore.questionsProgress));
    const isActivePremium = stores.user.isActivePremium;

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const [loading , setLoading] = useState(true);
    const [breadcrumb , setBreadcrumb] = useState(); 
    const [isRun , setIsRun ] = useState(false);
    const [section, setSection] = useState();
    const [type , setType] = useState();

    const basicBreadcrumb = [
        { text:'Pytania', link:'/dzialy' },
        { text:'Działy z części podstawowej', link:'/dzialy/czesc-podstawowa' },
        { text: TranslateNameFromPathForRouting(id) , link:'/dzialy/czesc-podstawowa/'+id}
    ]

    const specialistBreadcrumb = [
        { text:'Pytania', link:'/dzialy' },
        { text:'Działy z części specjalistycznej', link:'/dzialy/czesc-specjalistyczna' },
        { text: TranslateNameFromPathForRouting(id) , link:'/dzialy/czesc-specjalistyczna/'+id}
    ]

    const getTypeByPath = () => {
        if(window.location.pathname.includes('czesc-specjalistyczna')){
            return 'specialistQuesions';
        }
        else if(window.location.pathname.includes('czesc-podstawowa')){
            return 'basicQuestions';
        }else{
            return null;
        }
    }

    useEffect(() => {
        setType(getTypeByPath());
    }, [id])

    function initializeData(){
        if(type === 'specialistQuesions'){
            setBreadcrumb(specialistBreadcrumb);
            return getSectonFroSpecialistQuestions();
        }else if(type === 'basicQuestions'){
            setBreadcrumb(basicBreadcrumb);
            const section = TranslateNameFromPathToSectionForBasicQuestions(id);
            return section;
        }            
    }

    const getSectonFroSpecialistQuestions = () => {

        const selectedCategory = user.options.category;

        let section = TranslateNameFromPathToSectionForSpecialistQuestions(id);
        if(section !== null){
            section = section.filter(s => s.indexOf(selectedCategory) !== -1)[0];
            if(section === undefined){
                history.push("/nie-znaleziono-strony");
            }
            return section;
        }
    }

    useEffect(() => {

        if(user !== null && type !== undefined){

            let isValidateUrl = true;
            isValidateUrl = ValidateUrl(id, type);

            let section = initializeData();
            setSection(section);
            dispatch(setCurrentSection(section))

            if(questionsStore.questions.length === 0 || questionsStore.questionsAnswers === 0 || !isValidateUrl){
                setLoading(true);
            }else{
                dispatch(setActiveQuestion(type)); 
            }
    
            if(activeQuestion !== null && activeQuestion !== undefined && isValidateUrl){
                setLoading(false);
            }
    
            if(activeQuestion === undefined && isValidateUrl){
                handleNoQuestionsInSection();
            }
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id , questionsStore.questions , questionsStore.newChecksum, activeQuestion, user, isActivePremium, type])

    
    useEffect(() => {
        if(!isRun){
            dispatch(deleteAllQuestionFromExList());
            setIsRun(true);            
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRun])

    function handleNoQuestionsInSection(){
        
        if(type === 'specialistQuesions'){
            history.push("/dzialy/czesc-specjalistyczna");
        }else if(type === 'basicQuestions'){
            history.push("/dzialy/czesc-podstawowa");
        }  
       
        dispatch(showToast("Wszystkie pytania z działu zaliczone." , "success")).then(() => {
            dispatch(resetModalSettings());
            dispatch(resetActiveQuestion());
        })  

    }


    return (
        <>
            { loading && 
              user === null &&
                 <SmallSpinner 
                 isText={true}
                 isCenter={true}
              />
            }  
            {  !loading && activeQuestion !== null && activeQuestion !== undefined && user !== null &&
            <Layout title={breadcrumb} isProgressCircleActive={true} progress={questionsProgress.get(section)} color={'#0080FD'} withoutBottomMargin={true} isNotTopMargin={true}>
                <div className="Dashboard__flex">
                    <Question data={activeQuestion}/>
                    <div className='DeparmentClue--flex'>
                    {
                        (activeQuestion.tip1 || activeQuestion.tip2 || activeQuestion.tip3 ) &&
                        <div className="DeparmentClue">
                            {  activeQuestion.tip1 && <Clue link={activeQuestion.tip1Link} content={activeQuestion.tip1} />} 
                            {  activeQuestion.tip2 && <><Separator /><Clue link={activeQuestion.tip2Link} content={activeQuestion.tip2} /></>} 
                            {  activeQuestion.tip3 && <><Separator /><Clue link={activeQuestion.tip3Link} content={activeQuestion.tip3} /></>} 
                        </div>    
                    }
                    </div>      
                </div>
            </Layout>
            }
        </>
    )
}

export default DepartmentQuestion
