import { Formik, Form } from 'formik';
import React , { useState } from 'react';
import './Summary.css';
import * as Yup from 'yup';
import CheckboxField from '../../userPanel/checkboxField/CheckboxField';
import { useAuth } from '../../../contexts/AuthContext';
import Przelewy24Logo from '../../../images/Przelewy24_logo.svg';
import CustomButton from '../../../components/others/button/CustomButton';
import { showModal , closeModal } from '../../../redux/actions/notificationActions';
import { useDispatch } from 'react-redux';
import { ModalContent } from '../../../models/ModalContet';
import { useToastManager } from '../../../contexts/ToastManagerContext';
import agent from '../../../api/agent';

const Summary = (props) => {

  const przelewy24Label = 'Oświadczam, że zapoznałem się z <a href="https://www.przelewy24.pl/regulamin">regulaminem</a> i <a href="https://www.przelewy24.pl/obowiazek-informacyjny-rodo-platnicy">obowiązkiem informacyjnym</a> serwisu Przelewy24';

  const [ loading, setLoading ] = useState(false);
  const { currentUser } = useAuth();
  const { showToast } = useToastManager();
  const dispatch = useDispatch();

  const validate = Yup.object({
    checkboxPrzelewy24: Yup.boolean() 
      .oneOf([true], 'Wymagane jest zaakceptowanie zapoznania się z regulaminem i obowiązkiem informacyjnym serwisu Przelewy24')
      .required('Wymagane jest zaakceptowanie zapoznania się z regulaminem i obowiązkiem informacyjnym serwisu Przelewy24')              
    })

  
  const setEmailData = new ModalContent(
    "Warning",
    "Brak podanego adresu e-mail!",
    "W celu dokończenia procesu zakupu premium należy podać email.",
    null,
    () => dispatch(closeModal()),
    null,
    null,
    true
  )

  async function redirectToPrzelewy24(){ 
    if(currentUser.email === null || currentUser.email === undefined){
      dispatch(showModal(setEmailData));
    }else{
      try{
        setLoading(true);
        const response = await agent.Payments.registerTransaction(props.premiumData.sku);
        window.location.href = response;  
      }catch(ex){
        setLoading(false);
      }
    }
  }

  return (
    <div className='SelectedPremiumInformation__Container Summary__Container'>
      <h6>Podsumowanie:</h6>
        <div className='Summary__Item'>
          <p>{props.premiumData.firstText}</p>
          <p>{props.premiumData.price} zł</p>
        </div>
        <div className="AccountInformation__Separator Summary__Separator" />
          <div className='Summary__Item TotalPrice'>
            <p>Całkowita cena:</p>
            <b>{props.premiumData.price} zł</b>
          </div>

        <Formik
          initialValues={{
            checkboxPrzelewy24:''
          }}
            validationSchema={validate}
            onSubmit={values =>{
              redirectToPrzelewy24(values);
          }}
          >
          {formik => (
            <Form>
              <CheckboxField name="checkboxPrzelewy24" type="checkbox" text={przelewy24Label}/>
                <CustomButton
                  loading = {loading}
                  content = {'Kup premium'}
                  color = {'white'}
                  widthInPercent = {100}
                  type="submit"
                  alt="logo przelewy24"
                  image={Przelewy24Logo}
                 />
            </Form>
          )}
          </Formik>
      </div>
    )   
}

export default Summary 