import React , { useState } from 'react';
import { Card, Alert , Container } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { Link , useHistory } from "react-router-dom";
import FirebaseErrorTranslateCode from '../../helpers/FirebaseTranslateCode';
import logo from "../../images/logo.svg";
import './UserPanel.css';
import { Formik, Form } from 'formik';
import TextField from '../../components/userPanel/textField/TextField';
import CheckboxField from '../../components/userPanel/checkboxField/CheckboxField';
import * as Yup from 'yup';
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import CookieModal from '../../components/website/cookiesModal/CookieModal';
import { CookiesInformationData } from '../../data/CookiesInformationData';
import { detectNetworkConnection } from '../../helpers/DetectNetworkConnection';
import DetectBrowserVersion from '../../helpers/DetectBrowserVersion';
import Modal from '../../components/others/modal/Modal';
import { CheckLocalstorageIfEnable } from '../../helpers/CheckLocalstorageIfEnable';

export default function Signup() {

    const params = new URLSearchParams(window.location.search);
    const premiumCodeParameter = params.get('premiumCode');

    const validate = Yup.object({
        email: Yup.string()
            .email('Adres email jest nie poprawny')
            .required('Adres email jest wymagany'),
        password: Yup.string()
            .min(6 , 'Hasło musi mieć minimum 6 znaków')
            .required('Hasło jest wymagane'),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password'),null] ,'Hasła muszą być takie same')
            .required('Powtórzenie hasła jest wymagane'), 
        checkboxTermOfUse: Yup.boolean()
            .oneOf([true], 'Wymagana jest akceptacja regulaminu')
            .required('Wymagana jest akceptacja regulaminu'),
        checkboxPrivacyPolicy: Yup.boolean()
            .oneOf([true], 'Wymagana jest akceptacja polityki prywatności')
            .required('Wymagana jest akceptacja polityki prywatności')              
    })

    let termOfUseLabel = 'Oświadczam, że akceptuje <a href="/warunki-korzystania">warunki korzystania</a> z serwisu'
    let privacyPolicyLabel = 'Oświadczam, że zapoznałem się z <a href="/polityka-prywatnosci">polityką prywatności</a> serwisu'

    const { signup  } = useAuth()
    const [error , setError] = useState('')
    const history = useHistory()
    const [ loading , setLoading ] = useState(false)

    async function sendRegistration(values){
        setError('')
        setLoading(true)
        if(detectNetworkConnection() && !DetectBrowserVersion() && CheckLocalstorageIfEnable()){
            try{
                await signup(values.email , values.password).then(response => {
                    if(premiumCodeParameter === null){
                        history.push("/konto");
                    }else{
                        history.push(`/podsumowanie-platnosci/${premiumCodeParameter}`);
                    }              
                }).catch(error => {
                    const polishError = FirebaseErrorTranslateCode(error.code)
                    setError(polishError)
                })
             }
             catch (e){
                console.log(e);
                setError('Nie udało się stworzyć konta')
            }
        }
        setLoading(false)
    }
    
    return (
        <>
        <Formik
        initialValues={{
            email:'',
            password:'',
            passwordConfirm:'',
            checkboxTermOfUse:'',
            checkboxPrivacyPolicy:''
        }}
        validationSchema={validate}
        onSubmit={values =>{
            sendRegistration(values)
        }}
        >
            {formik => (
                <Container className="d-flex align-items-center justify-content-center UserPanel__Container UserPanelSection">
                    <div className="w-100" style={{ maxWidth: "400px"}}>
                        <Card>
                            <Card.Body>
                                <div className="UserPanel__Logo">
                                    <Link to='/'>
                                        <img src={logo} alt="logo" />
                                    </Link>
                                </div>
                            { error && <Alert variant="danger" className="alert-danger-custom">{error}</Alert>}
                            <Form>
                                <TextField label="Email" name="email" type="email" placeholder="Adres email"/>
                                <TextField label="Hasło" name="password" type="password" placeholder="Hasło"/>
                                <TextField label="Powtórz hasło" name="passwordConfirm" type="password" placeholder="Powtórz hasło"/>
                                <CheckboxField name="checkboxTermOfUse" type="checkbox" text={termOfUseLabel}/>
                                <CheckboxField name="checkboxPrivacyPolicy" type="checkbox" text={privacyPolicyLabel}/>
                                <button disabled={loading}  className={loading ? 'UserPanel__button button--loading' : 'UserPanel__button button--blue'} type="submit">
                                    {loading ? <SmallSpinner isText={false} />: "Załóż darmowe konto"}
                                </button>
                                <div className="UserPanel__smallText text-center">Administratorem Twoich danych osobowych jest Necuit.com - Nec Łukasz (NIP 9291774170). <br/> E-mail: kontakt@prawojazdy.pro</div>
                            </Form>
                            </Card.Body>
                        </Card>
                        <div className="w-100 text-center mt-2">
                            Posiadasz już konto? 
                            <Link to={premiumCodeParameter === null ? "/logowanie" : `/logowanie?premiumCode=${premiumCodeParameter}`} className='UserPanel__link'> Zaloguj się</Link>
                        </div>
                    </div>
                </Container>
            )}
        </Formik>     
        <CookieModal data={CookiesInformationData}/>  
        <Modal />
        </>
    )
}
