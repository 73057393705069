import React , { useEffect , useState } from 'react';
import SectionDashboard from '../../components/dashboard/section/Section';
import { useSelector  } from 'react-redux';
import { Section } from '../../models/Section'
import SmallSpinner from '../../components/others/spinner/SmallSpinner';
import { SpecialistQuestionsSection } from '../../data/Sections/SpecialistQuestionsSection';

const Department = () => {

    const questionsStore = useSelector((state) => state.questions);
    const user = useSelector((state) => state.user.user);
    const isActivePremium = useSelector((state) => state.user.isActivePremium);

    const pathname = window.location.pathname;

    const mainDepartmentSection = [
        new Section("M", "basic", "Działy z części podstawowej", '/dzialy/czesc-podstawowa'),
        new Section("M", "specialist", "Działy z części specjalistycznej", '/dzialy/czesc-specjalistyczna')
    ]
    const mainBreadcrumb = [{ text:'Pytania', link:'/dzialy' }]
    const basicQuestionsBreadcrumb = [{ text:'Pytania', link:'/dzialy' },{ text:'Działy z części podstawowej', link:'/dzialy/czesc-podstawowa' }]
    const specialistQuestionsBreadcrumb = [{ text:'Pytania', link:'/dzialy' }, { text:'Działy z części specjalistycznej', link:'/dzialy/czesc-specjalistyczna' }]

    const [ loading , setLoading ] = useState(true)
    const [ section , setSection ] = useState();
    const [ breadcrumb , setBreadcrumb ] = useState();
    const [ progress , setProgress ] = useState();
    const [ activeResetProgress, setActiveResetProgress ] = useState(false);
    const [ type, setType ] = useState();

    useEffect(() => {
        if(isActivePremium !== null && Object.keys(questionsStore.questionsProgress).length !== 0){
            let nameOfPage = pathname.substring(pathname.lastIndexOf('/') + 1);
            switch(nameOfPage) {
                case 'dzialy':
                    setSection(mainDepartmentSection);
                    setBreadcrumb(mainBreadcrumb);
                    setProgress(new Map(Object.entries(questionsStore.questionsProgress)));
                    setActiveResetProgress(false)
                    setTimeout(()=>setLoading(false), 200)
                    break;
                case 'czesc-podstawowa':
                    setSection(questionsStore.basicQuestionsSections);
                    setBreadcrumb(basicQuestionsBreadcrumb);
                    setProgress(new Map(Object.entries(questionsStore.questionsProgress)));
                    setActiveResetProgress(true);
                    setType('p');
                    setTimeout(()=>setLoading(false), 200)
                    break;
                case 'czesc-specjalistyczna':               
                    if(user !== null){
                        setSection(SpecialistQuestionsSection(user.options.category));
                        setBreadcrumb(specialistQuestionsBreadcrumb);
                        setProgress(new Map(Object.entries(questionsStore.questionsProgress)));
                        setActiveResetProgress(true);
                        setType('s');
                        setTimeout(()=>setLoading(false), 200)
                    }           
                    break;
                default:
                    setSection(mainDepartmentSection);
                    setBreadcrumb(mainBreadcrumb);
                    setProgress(new Map(Object.entries(questionsStore.questionsProgress)));
                    setTimeout(()=>setLoading(false), 200)
                    break;
            }
        }  
    //eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [pathname, questionsStore.loading , questionsStore.questionsProgress, isActivePremium]);

    return (
        <>
            { loading && 
              <SmallSpinner 
                 isText={true}
                 isCenter={true}
              />
            }  
            { 
                !loading && 
                <SectionDashboard 
                    colorOfProgressCircle={'#0080FD'} 
                    title={breadcrumb} 
                    data={section} 
                    progress={progress}
                    activeResetProgress={activeResetProgress}
                    messageForToast={"Wszystkie pytania z działu zaliczone"}
                    isActivePremium={isActivePremium}
                    type={type}
                /> 
            }
        </>
    )
}

export default Department
