export const PrivacyPolicyData = {
    id: 0,
    mainTitle: "Polityka Prywatności",
    mainDesc: "Niniejsza Polityka stanowi podstawową informację o celach, sposobach przetwarzania oraz bezpieczeństwie Twoich danych osobowych, jako użytkownika strony: www.prawojazdy.pro (zwanej dalej Stroną). Zapoznając się z Polityką prywatności dowiesz się kto jest administratorem Twoich danych osobowych, jakie dane osobowe są przez Stronę zbierane, w jakich celach są wykorzystywane oraz jak są chronione.",
    paragraph:
    [
        {
            header:"§1. Informacje podstawowe.",
            desc: 
            [
                "1.	Administratorem Twoich danych osobowych jest Necuit.com - Nec Łukasz (NIP 9291774170) z siedzibą w Drzonowie 20 66-008.",
                "2.	Kontakt z Administratorem jest możliwy za pośrednictwem poczty e-mail: kontakt@prawojazdy.pro"
            ]
        },    
        {
            header:"§2. Zasady przetwarzania danych.",
            desc: 
            [
                "Administrator przetwarza dane osobowe z poszanowaniem następujących zasad:  ",
                "1)	w oparciu o podstawę prawną i zgodnie z prawem (legalizm)  ",
                "2)	rzetelnie i uczciwie (rzetelność)  ",
                "3)	w sposób przejrzysty dla osoby, której dane dotyczą (transparentność)  ",
                "4)	w konkretnych celach i nie „na zapas” (minimalizacja)  ",
                "5)	nie więcej niż potrzeba (adekwatność)  ",
                "6)	z dbałością o prawidłowość danych (prawidłowość)  ",
                "7)	nie dłużej niż potrzeba (czasowość)  ",
                "8)	zapewniając odpowiednie bezpieczeństwo danych (bezpieczeństwo)  "
            ]
        },
        {
            header:"§3. Cele i podstawy prawne przetwarzania.",
            desc: 
            [
                "Twoje dane będziemy przetwarzać:  ",
                "1. W celach zapewnienia możliwości korzystania z serwisu prawojazdy.pro oraz do umożliwienia korzystania z odpłatnych części serwisu na podstawie prawnej niezbędność do wykonania umowy o świadczeniu usługi konta (art. 6 ust. 1 lit. b RODO)  ",
                "2. W celach kontaktowych, aby udzielić ci odpowiedzi na Twoją wiadomość przesłaną nam bezpośrednio drogą e-mailową lub za pośrednictwem formularza na podstawie realizacji prawnie uzasadnionego interesu Administratora związanego z koniecznością udzielenia Ci odpowiedzi na podstawie prawnej niezbędności do wykonania umowy o świadczenie usługi formularza kontaktowego (art. 6 ust. 1 lit. b RODO).  ",
                "3. W celach statystycznych, analitycznych oraz monitorowania ruchu na Stronie przy użyciu plików cookies na podstawie realizacji prawnie uzasadnionego interesu Administratora związanego z prawidłowym działaniem i funkcjonowaniem Strony oraz prowadzeniem analizy ruchu na stronie.  "
            ]
        },
        {
            header:"§4. Źródła danych osobowych",
            desc: 
            [
                "Niezbędne dane osobowe potrzebne do rejestracji tj. adres email w przypadku rejestracji poprzez platformy Google lub facebook są pobierane z wyżej wymienionych platform.  ",
            ]
        },
        {
            header:"§5. Google Firebase Authentication",
            desc: 
            [
                "Do systemu autoryzacji użytkownika została wykorzystana baza danych Firebase. Transmisja danych osobowych tj. adresu email odbywa się poprzez serwis google Firebase. Dane osobowe użytkownika nie są w żaden inny sposób przekazywane przez nas osobom trzecim. Polityka prywatności Google Firebase jest dostępna pod adresem <a href=\"https://firebase.google.com/support/privacy\">polityka prywatności firebase</a>. Podstawą prawną dla przetwarzania danych jest art. 6 ust. 1 lit f RODO."
            ]
        },
        {
            header:"§6. Zakres przetwarzanych danych osobowych.",
            desc: 
            [
                "Przetwarzamy Twoje dane osobowe w zakresie:  ",
                "1. W zakresie obsługi i dostarczenia Ci możliwości pełnego korzystania z serwisu.  ",
                "2. W przypadku skorzystania z formularzu kontaktowego na naszej stronie przetwarzane będą dane w postaci Twojego adresu e-mail oraz podanego imienia.  ",
                "3. Pozostałe dane, które są przez nas przetwarzane to inne dane zapisywane w plikach cookies oraz adres IP służące do analizy korzystania przez Ciebie i innych użytkowników serwisu."
            ]
        },
        {
            header:"§7. Termin przechowywania danych.",
            desc: 
            [
                "Twoje dane osobowe przetwarzane są do momentu:  ",
                "1) Likwidacji konta.  ",
                "2) Do momentu złożenia żądania usunięcia danych lub sprzeciwu wobec przetwarzania, lub w przypadku, w którym uznamy, że zrealizowaliśmy prawnie uzasadniony interes serwisu.  "     
            ]
        },
        {
            header:"§8. Prawa użytkowników.",
            desc: 
            [
                "W związku z przetwarzaniem Twoich danych przysługują Ci następujące prawa:   ",
                "1.	dostępu do treści swoich danych oraz  ",
                "2.	prawo ich sprostowania, usunięcia, ograniczenia przetwarzania,  ",
                "3.	prawo do przenoszenia danych,  ",
                "4.	prawo wniesienia sprzeciwu,  ",
                "5.	prawo do cofnięcia zgody na ich przetwarzanie w dowolnym momencie i w dowolnej formie, chyba że przetwarzanie Państwa danych odbywa się w celu wykonywania umowy przez Administratora, w celu wywiązania się przez Administratora z obowiązków prawnych względem instytucji państwowych lub w celu realizacji prawnie uzasadnionych interesów Administratora.  ",
                "6.	Masz także prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (na adres Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa).  ",
                "7.	Więcej informacji w przedmiocie ochrony danych osobowych mogą Państwo otrzymać na stronie internetowej Urzędu Ochrony Danych Osobowych: www.uodo.gov.pl  "
            ]
        },
        {
            header:"§9. Pliki cookies.",
            desc: 
            [
                "1. Serwis wykorzystuje pliki cookies do prawidłowego funkcjonowania portalu, nie są w żaden sposób wykorzystywane.  ",
                "2. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym, czyli Twoim komputerze, laptopie lub smartfonie, w zależności jakiego urządzenia używasz do oglądania Strony. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer. Sam możesz zadecydować o formie wykorzystania cookies – ustawienia te są dostępne w każdej przeglądarce internetowej.  ",
                "3. Użytkownik może w każdej chwili usunąć pliki cookies ze swojej przeglądarki.  ",
                "4. W przypadku nie wyrażania zgody na wykorzystawnie cookies przez serwis, opuść stronę lub aktywuj odpowiednie opcje w ustawieniach swojej przeglądarki internetowej.  ",
            ]
        },
        {
            header:"§10. Sposób chronienia danych osobowych",
            desc: 
            [
                "Przesyłanie danych odbywa się poprzez bezpieczny protokół SSL zwiększający ochronę przesyłu danych. Wszystkie informacje są przetwarzane i przechowywane z zachowaniem wszystkich odpowiednich środków bezpieczeństwa według reguł polskiego prawa.  ",
            ]
        },
        {
            header:"§11. Zmiany",
            desc: 
            [
                "Serwis Prawojazdy.pro zastrzega sobie prawo do zmiany Polityki prywatności, informacja będzie podawana w serwisie z odpowiednim wyprzedzeniem czasu a użytkowników korzystających z serwisu obowiązuje zamieszczona Polityka Prywatności"
            ]
        },
        {
            header:"§12. Hosting",
            desc: 
            [
                "Serwis Prawojazdy.pro jest hostowany nas serwerach OVH. Firma hostingowa przestrzega postanowienia regulacji prawnych odnoszących się do ochrony danych osobowych. Więcej informacji na temat ochrony danych osobowych stosowanych przez firmę hostingową OVH dostępna jest pod adresem <a href=\"https://www.ovh.pl/ochrona-danych-osobowych/\">ochrona danych osobowych</a>.",
                "<b>Dane rejestrowy firmy zajmującej się hostingiem serwisu Prawojazdy.pro: </b>",
                "1. OVH Sp. z o.o",
                "2. Kapitał zakładowy: 50000,00 zł",
                "3. Sąd Rejonowy dla Wrocławia - Fabrycznej we Wrocławiu VI Wydział Gospodarczy Krajowego Rejestru Sądowego",
                "4. Nr KRS: 0000220286",
                "5. REGON: 933029040 NIP: 899-25-20-556",
                "6. ul. Swobodna 1 50-088 Wrocław",
                "7. Prezes Zarządu: Miroslaw Klaba",
                "8. OVH Sp.zoo jest spółką zależną spółki OVH Groupe SAS, zarejestrowanej w Rejestrze Handlowym Spółek w Lille pod numerem 537 407 926, z siedzibą pod adresem 2, rue Kellermann, 59100 Roubaix.",
                "<b>Firma zajmująca się hostingiem serwisu Prawojazdy.pro:</b>",
                "1. stosuje środki ochrony przed utratą danych (np. macierze dyskowe, regularne kopie bezpieczeństwa)",
                "2. stosuje adekwatne środki ochrony miejsc przetwarzania na wypadek pożaru (np. specjalne systemy gaśnicze)",
                "3. stosuje adekwatne środki ochrony systemów przetwarzania na wypadek nagłej awarii zasilania (np. podwójne tory zasilania, agregaty, systemy podtrzymania napięcia UPS)",
                "4. stosuje środki fizycznej ochrony dostępu do miejsc przetwarzania danych (np. kontrola dostępu, monitoring)",
                "5. stosuje środki zapewnienia odpowiednich warunków środowiskowych dla serwerów jako elementów systemu przetwarzania danych (np. kontrola warunków środowiskowych, specjalistyczne systemy klimatyzacji)",
                "6. stosuje rozwiązania organizacyjne dla zapewnienia możliwie wysokiego stopnia ochrony i poufności (szkolenia, wewnętrzne regulaminy, polityki haseł itp.)",
                "7. powołała Inspektora Ochrony Danych.",
                "<b>Firma zajmująca się hostingiem serwisu Prawojazdy.pro w celu zapewnienia niezawodności technicznej prowadzi logi na poziomie serwera. Zapisowi mogą podlegać: </b>",
                "1. zasoby określone identyfikatorem URL (adresy żądanych zasobów stron, plików)",
                "2. czas nadejścia zapytania",
                "3. czas wysłania odpowiedzi",
                "4. nazwę stacji klienta identyfikacja realizowana przez protokół HTTP",
                "5. informacje o błędach jakie nastąpiły przy realizacji transakcji HTTP",
                "6. adres URL strony poprzednio odwiedzanej przez użytkownika (referer link) w przypadku gdy przejście do Serwisu nastąpiło przez odnośnik",
                "7. informacje o przeglądarce użytkownika",
                "8. informacje o adresie IP",
                "9. informacje diagnostyczne związane z procesem samodzielnego zamawiania usług poprzez rejestratory na stronie",
                "10. informacje związane z obsługą poczty elektronicznej kierowanej do Operatora oraz wysyłanej przez Operatora"
            ]
        },
        {
            header:"§13. Usuwanie konta",
            desc: 
            [
                "W celu usunięcia konta użytkownika z portalu prawojazdy.pro należy wejść w ustawienia i nacisnąć przycisk usuń konto.",
                "W przypadku rejestracji przez portal społecznościowy facebook, aby całkowicie usunąć aplikacje z swojego konta należy posłużyć się instrukcją <a href=\"https://www.facebook.com/help/170585223002660\">instrukcja usunięcia aplikacji z facebooka.</a>"
            ]
        },
        {
            header:"§14. Google Analytics",
            desc:
            [
                "Serwis Prawojazdy.pro korzysta z usługi Google Analytics, dostarczanej przez firmę Google LLC („Google”). Google Analytics wykorzystuje pliki cookies, które są przechowywane na Twoim urządzeniu i umożliwiają analizę korzystania z serwisu przez użytkowników. Informacje generowane przez pliki cookies na temat korzystania z serwisu są przekazywane i przechowywane na serwerach Google w Stanach Zjednoczonych.",
                "Administrator tej strony internetowej używa Google Analytics w celu monitorowania ruchu na stronie, analizy zachowań użytkowników oraz zbierania danych statystycznych na temat używania serwisu. Google będzie przetwarzać te informacje w imieniu Administratora w celu oceny korzystania z serwisu, tworzenia raportów o aktywności na stronie oraz świadczenia innych usług związanych z ruchem na stronie i korzystaniem z internetu.",
                "W ramach usługi Google Analytics, Administrator może zbierać informacje takie jak adres IP, rodzaj przeglądarki, rodzaj urządzenia, język przeglądarki, system operacyjny, źródło, strony odwiedzone na serwisie, czas spędzony na stronach oraz interakcje użytkowników z serwisem.",
                "Polityka prywatności Google Analytics jest dostępna pod adresem <a href=\"https://policies.google.com/privacy\">polityka prywatności Google Analytics</a>. Podstawą prawną dla przetwarzania danych jest art. 6 ust. 1 lit f RODO."
            ]
        }
    ]
}
